import { css } from "@emotion/react";
import React, { forwardRef, useCallback, useMemo, useState } from "react";

import pxToRem from "../../../styles/px-to-rem";
import { touchScrollY } from "../../../styles/touch-scroll";
import variables from "../../../styles/variables";
import Flyout from "../../common/flyout";
import MultiSelectContext from "./multi-select-context";
import { MultiSelectOption } from "./index";
import { transformReactElementsToOptions } from "../../../helpers/form";
import CaretDownIcon from "../../../public/static/icons/caret-down.svg";
import CloseCircleIcon from "../../../public/static/icons/close-circle.svg";
import { h5Bold, h5Regular } from "../../../styles/typography";
import MultiSelectOptionAll from "./multi-select-option-all";

const styles = {
  flyout: css`
    min-height: unset;
  `,
  optionContainer: css`
    ${touchScrollY};
    max-height: ${pxToRem(180)};
    padding: ${pxToRem(6)} 0;
  `,
  svgIcon: css`
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};

    path {
      fill: ${variables.colorSecondaryGray};
    }
  `,
  triggerIconContainer: css`
    display: flex;
    justify-content: end;
    align-items: center;
  `,
  triggerButton: css`
    ${h5Bold};
    position: relative;
    width: 100%;
    padding: ${pxToRem(8)} ${pxToRem(12)} ${pxToRem(8)} ${variables.spaceSmall};
    border: ${pxToRem(1)} solid ${variables.colorSecondaryGray};
    border-radius: ${variables.roundness2};
    background: ${variables.colorWhite};
    color: ${variables.colorPrimaryGray};

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 16px;
    justify-content: space-between;
    align-items: center;

    &:disabled {
      border-color: ${variables.colorSecondaryGray};
      cursor: not-allowed;
      background: ${variables.colorContainerGray};
    }
  `,
  triggerPlaceholder: css`
    color: ${variables.colorSecondaryGray};
  `,
  triggerButtonError: css`
    border-color: ${variables.colorError};
  `,
  triggerButtonWarning: css`
    border-color: ${variables.colorWarningBorder};
  `,
  allowClearIcon: css`
    &:hover {
      .caret-down-icon {
        display: none;
      }
      .close-circle-icon {
        display: unset;
      }
    }
  `,
  clearIcon: css`
    display: none;
  `,
  noOptionAvailable: css`
    ${h5Regular};
    color: ${variables.colorSecondaryGray};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${variables.spaceMicro};
  `,
};

const MultiSelect = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  <T extends any>(
    {
      allowClear = false,
      allowSelectAll = false,
      id = "multi-select-input",
      className,
      disabled = false,
      dropdownMatchTriggerWidth = true,
      footer = null,
      children,
      options = [],
      placeholder = "Select...",
      preferredAlignment = "left",
      status,
      triggerClassName,
      value = [],
      renderTrigger,
      onClear = () => {},
      onChange = () => {},
    }: MultiSelectProps<T>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const optionsObj = useMemo<Record<OptionValue, Option>>(() => {
      let newOptions: Option[] = undefined;

      if (children) {
        newOptions = transformReactElementsToOptions(children);
      } else {
        newOptions = [...options];
      }

      return newOptions.reduce(
        (result, item) => ({ ...result, [item.value]: item }),
        {}
      );
    }, [children, options]);

    const valueMap = useMemo(() => {
      const mapObj = new Map<OptionValue, Option>();
      return value.reduce((result, value) => {
        result.set(value, optionsObj[value]);
        return result;
      }, mapObj);
    }, [optionsObj, value]);

    const selectedOptions = useMemo(() => {
      return Array.from(valueMap.keys()).map(
        (value) => valueMap.get(value) ?? { label: value, value }
      );
    }, [valueMap]);

    const handleSelectAll = useCallback(
      (isSelect) => {
        if (isSelect) {
          onChange(
            options.map((item) => item.value),
            options
          );
        } else {
          onChange([], []);
        }
      },
      [value, options, onChange]
    );

    const handleSelect = useCallback(
      (option: Option) => {
        const isSelectEvent = valueMap.get(option.value) === undefined;

        const values = Array.from(valueMap.keys());
        let newValues: OptionValue[] = undefined;

        if (isSelectEvent) {
          newValues = [...values, option.value];
        } else {
          newValues = values.filter((value) => value !== option.value);
        }

        const options = newValues.map(
          (value) => optionsObj[value] ?? { label: value, value }
        );
        onChange(newValues, options);
      },
      [optionsObj, valueMap, onChange]
    );

    const handleClear = () => {
      onClear();
      onChange([], []);
    };

    const optionComponents = useMemo(() => {
      if (!children?.length && !options.length) {
        return <div css={styles.noOptionAvailable}>Empty options</div>;
      }

      if (children) {
        return children;
      }

      return options.map((option) => {
        return (
          <MultiSelectOption key={option.value} value={option.value}>
            <div>{option.label}</div>
          </MultiSelectOption>
        );
      });
    }, [children, options]);

    const trigger = useMemo(() => {
      if (renderTrigger) {
        return renderTrigger(selectedOptions);
      }

      if (selectedOptions.length === 0) {
        return <span>{placeholder}</span>;
      }

      return selectedOptions.map((item) => item.label).join(", ");
    }, [placeholder, selectedOptions, renderTrigger]);

    const getTriggerStatusCss = (status) => {
      switch (status) {
        case "warning":
          return styles.triggerButtonWarning;
        case "error":
          return styles.triggerButtonError;
        default:
          return undefined;
      }
    };

    const isSelectedAll = useMemo(() => {
      if (!allowSelectAll) {
        return undefined;
      }

      return options.every((item) => valueMap.get(item.value) !== undefined);
    }, [allowSelectAll, options, valueMap]);

    return (
      <div ref={ref} className={className}>
        <Flyout
          id={id}
          css={styles.flyout}
          preferredAlignment={preferredAlignment}
          dropdownMatchTriggerWidth={dropdownMatchTriggerWidth}
          trigger={
            <button
              type="button"
              css={[
                styles.triggerButton,
                value.length === 0 && styles.triggerPlaceholder,
                value.length > 0 && allowClear && styles.allowClearIcon,
                getTriggerStatusCss(status),
                triggerClassName,
              ]}
              disabled={disabled}
            >
              {trigger}
              <div css={styles.triggerIconContainer}>
                <CaretDownIcon
                  css={styles.svgIcon}
                  className="caret-down-icon"
                />
                {allowClear && (
                  <CloseCircleIcon
                    css={[styles.svgIcon, styles.clearIcon]}
                    className="close-circle-icon"
                    onClick={handleClear}
                  />
                )}
              </div>
            </button>
          }
          isOpen={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        >
          <MultiSelectContext.Provider
            value={{
              valueMap,
              onClick: handleSelect,
            }}
          >
            <div css={styles.optionContainer}>
              {allowSelectAll && (
                <MultiSelectOptionAll
                  isChecked={isSelectedAll}
                  onClick={() => handleSelectAll(!isSelectedAll)}
                />
              )}
              {optionComponents}
            </div>
          </MultiSelectContext.Provider>
          {footer && <div className="footer">{footer}</div>}
        </Flyout>
      </div>
    );
  }
);

MultiSelect.displayName = "MultiSelect";

export default MultiSelect as <T>(
  p: MultiSelectProps<T> & ForwardedRefComponentKeys<HTMLDivElement>
) => React.ReactElement;

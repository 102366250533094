import { css } from "@emotion/react";
import React, { ReactChild } from "react";
import pxToRem from "../../styles/px-to-rem";
import { h5Regular } from "../../styles/typography";
import variables from "../../styles/variables";

type Props = {
  children: ReactChild | ReactChild[];
  label: string;
};

const styles = {
  optionItem: css`
    ${h5Regular};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${pxToRem(6)} ${pxToRem(6)} ${pxToRem(6)} ${pxToRem(12)};
    cursor: unset;
    color: ${variables.colorPrimaryGray};
  `,
};

const SelectOptionGroup = ({ children, label }: Props) => {
  return (
    <>
      <div css={styles.optionItem}>{label}</div>
      {children}
    </>
  );
};

export default SelectOptionGroup;

import React, { useContext } from "react";
import selectOptionStyles from "../../../styles/select-option";
import MultiSelectContext from "./multi-select-context";
import CheckIcon from "../../../public/static/icons/check.svg";
import { css } from "@emotion/react";
import variables from "../../../styles/variables";
import pxToRem from "../../../styles/px-to-rem";

const styles = {
  checkIcon: css`
    height: ${pxToRem(22)};
    path {
      fill: ${variables.colorPrimaryGray};
    }
  `,
};

const MultiSelectOption = ({
  children,
  disabled = false,
  title,
  value,
}: MultiSelectOptionProps) => {
  const { valueMap, onClick } = useContext(MultiSelectContext);

  const isSelected = valueMap.get(value) !== undefined;
  const onClickCallback = disabled
    ? undefined
    : () => onClick({ value, label: children });

  return (
    <div
      css={[
        selectOptionStyles.optionItem,
        isSelected && selectOptionStyles.selectedOptionItem,
        disabled && selectOptionStyles.optionItemDisabled,
      ]}
      aria-hidden="true"
      title={title}
      onKeyUp={onClickCallback}
      onClick={onClickCallback}
    >
      {isSelected && <CheckIcon css={styles.checkIcon} />}
      {children}
    </div>
  );
};

export default MultiSelectOption;

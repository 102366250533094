import { createContext } from "react";

type ContextType = {
  valueMap: Map<OptionValue, Option>;
  onClick: (option: Option) => void;
};

const MultiSelectContext = createContext<ContextType>({
  valueMap: new Map(),
  onClick: () => {},
});

export default MultiSelectContext;

import { css } from "@emotion/react";
import pxToRem from "./px-to-rem";
import variables from "./variables";

const textInput = css`
  appearance: none;
  width: 100%;
  padding: ${pxToRem(12)} ${pxToRem(16)};
  border: ${pxToRem(1)} solid ${variables.colorSecondaryGray};
  border-radius: ${variables.roundness1};
  font-family: ${variables.baseFontFamily};
  font-size: ${pxToRem(14)};
  outline: none;
  text-overflow: ellipsis;
  transition: border-color ${variables.transitionSpeed} ease;

  &:focus {
    border-color: ${variables.colorHighlightBlue};
  }

  &:disabled {
    border-color: ${variables.colorSecondaryGray};
    cursor: not-allowed;
    background: ${variables.colorContainerGray};
  }
`;

export default textInput;

import { FieldError } from "react-hook-form/dist/types/errors";
import { MultiSelectOption } from "../components/form/multi-select";
import SelectOptionGroup from "../components/form/select-option-group";

export const setFormTouched = (
  values: Record<string, unknown>,
  setValue: (key: string, value: any, configs: Record<string, unknown>) => void
) => {
  for (const key in values) {
    setValue(key, values[key], { shouldTouch: true });
  }
};

const isValid = (touchedField: boolean, error: FieldError) => {
  return touchedField && error?.message === undefined;
};

export const isFieldValid = (fieldName: string, { errors, touchedFields }) => {
  return isValid(touchedFields[fieldName], errors?.[fieldName]);
};

export const getFieldStatus = (
  fieldName: string,
  { errors, touchedFields }
): InputStatus => {
  if (touchedFields[fieldName] === undefined) {
    return undefined;
  }

  return isFieldValid(fieldName, {
    errors,
    touchedFields,
  })
    ? undefined
    : "error";
};

export const isNestedFieldValid = (
  touchedField: boolean,
  error: FieldError
) => {
  return isValid(touchedField, error);
};

export const mergeRefs = (...refs) => {
  return (node) => {
    for (const ref of refs) {
      if (!ref) continue;
      ref.current = node;
    }
  };
};

const _transformReactElementToOption = ({ props }: JSX.Element): Option => {
  const { value, children: label } = props;
  return { value, label };
};

export const transformReactElementsToOptions = (
  components: JSX.Element[]
): Option[] => {
  return components.reduce((result, item) => {
    if (item.type.name === SelectOptionGroup.name) {
      return [
        ...result,
        ...transformReactElementsToOptions(item.props.children),
      ];
    } else if ([MultiSelectOption.name].includes(item.type.name)) {
      return [...result, _transformReactElementToOption(item)];
    }

    return result;
  }, []);
};

export const scrollToError = (
  errors,
  scrollBehavior: ScrollIntoViewOptions = { behavior: "smooth" }
) => {
  const errorsValues: FieldError[] = Object.values(errors);
  if (errorsValues.length === 0) {
    return;
  }

  const element = document.getElementsByName(errorsValues[0].ref.name)[0];
  element.scrollIntoView(scrollBehavior);
};

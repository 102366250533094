import React, { useState, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/react/types";

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import CheckboxWithLabel from "./checkbox-with-label";
import i18n from "../../helpers/i18n";
import textInput from "../../styles/text-input";
import { mergeRefs } from "../../helpers/form";

const validInput = css`
  border-color: ${variables.colorMainGreen};
`;

const invalidInput = css`
  border-color: ${variables.colorError};
`;

const passwordInput = css`
  margin-bottom: ${variables.spaceTiny};
`;

const messageRegion = css`
  margin-top: ${variables.spaceTiny};
  margin-bottom: ${variables.spaceMicro};
`;

const hint = css`
  margin-top: ${variables.spaceTiny};
  font-size: ${pxToRem(14)};
  color: ${variables.colorPrimaryGray};
  line-height: 1.4;
`;

const error = css`
  font-size: ${pxToRem(14)};
  color: ${variables.colorError};

  &:first-letter {
    text-transform: uppercase;
  }
`;

type Input = {
  style?: SerializedStyles;
  id?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  isValid?: boolean;
  errorMessage?: string;
  hintMessages?: string[];
  type?: string;
  onChange: (SyntheticEvent) => void;
  onBlur?: (SyntheticEvent) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  dataTest?: string;
  min?: number;
  max?: number;
  step?: number;
};

const TextInput = React.forwardRef<HTMLInputElement, Input>(
  (
    {
      id,
      name,
      value = "",
      isValid,
      errorMessage,
      type = "text",
      hintMessages,
      onChange,
      onBlur,
      onKeyPress,
      autoComplete = "off",
      placeholder,
      style,
      disabled = false,
      autoFocus,
      dataTest,
      min,
      max,
      step,
    }: Input,
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [inputType, setInputType] = useState(type);
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
      if (showPassword) {
        setInputType("text");
      } else {
        setInputType(type);
      }
    }, [showPassword]);

    useEffect(() => {
      if (autoFocus) inputRef?.current?.focus();
    }, [autoFocus]);

    return (
      <div css={style}>
        <input
          ref={mergeRefs(ref, inputRef)}
          id={id}
          name={name}
          value={value ?? ""}
          css={[
            textInput,
            isValid === true ? validInput : null,
            isValid === false ? invalidInput : null,
            type === "password" ? passwordInput : null,
          ]}
          type={inputType}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete={autoComplete}
          placeholder={placeholder}
          disabled={disabled}
          data-test={dataTest}
          min={min}
          max={max}
          step={step}
          onKeyPress={onKeyPress}
        />
        {type === "password" && (
          <CheckboxWithLabel
            label={i18n.t("showPassword")}
            onToggle={() => setShowPassword(!showPassword)}
            isChecked={showPassword}
          />
        )}
        <div
          css={(errorMessage || hintMessages) && messageRegion}
          role="region"
          aria-live="polite"
        >
          {errorMessage && <p css={error}>{errorMessage}</p>}
          {hintMessages?.map((message) => (
            <p key={message} css={hint}>
              {message}
            </p>
          ))}
        </div>
      </div>
    );
  }
);

TextInput.displayName = "TextInput";

export default TextInput;
export type { Input };

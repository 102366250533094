import React, { useState, useEffect, ReactElement } from "react";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/react/types";

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import { h5Regular, truncate } from "../../styles/typography";

const checkboxUnselectedIcon = encodeURIComponent(
  `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M15,1H3A2,2,0,0,0,1,3V15a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V3A2,2,0,0,0,15,1Z" fill="#f7fbff"/><path d="M15,0H3A3,3,0,0,0,0,3V15a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V3A3,3,0,0,0,15,0Zm2,15a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V3A2,2,0,0,1,3,1H15a2,2,0,0,1,2,2Z" fill="#94aabf"/></svg>`
);

const checkboxUnselectedHoverIcon = encodeURIComponent(
  `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M15,1H3A2,2,0,0,0,1,3V15a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V3A2,2,0,0,0,15,1Z" fill="#f7fbff"/><path d="M15,0H3A3,3,0,0,0,0,3V15a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V3A3,3,0,0,0,15,0Zm2,15a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V3A2,2,0,0,1,3,1H15a2,2,0,0,1,2,2Z" fill="#19C2EF"/></svg>`
);

const checkboxSelectedIcon = encodeURIComponent(
  `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M15,1H3A2,2,0,0,0,1,3V15a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V3A2,2,0,0,0,15,1Z" fill="#f7fbff"/><path d="M15,0H3A3,3,0,0,0,0,3V15a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V3A3,3,0,0,0,15,0Zm2,15a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V3A2,2,0,0,1,3,1H15a2,2,0,0,1,2,2Z" fill="#17b97c"/><path d="M14.28,4.66a1,1,0,0,0-1.39,0L7.33,10,5.11,7.86a1,1,0,0,0-1.39,0l-.64.62a1,1,0,0,0,0,1.44l3.56,3.42a1,1,0,0,0,1.39,0l6.89-6.62a1,1,0,0,0,0-1.44Z" fill="#17b97c"/></svg>`
);

const checkboxSelectedHoverIcon = encodeURIComponent(
  `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M15,1H3A2,2,0,0,0,1,3V15a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V3A2,2,0,0,0,15,1Z" fill="#f7fbff"/><path d="M15,0H3A3,3,0,0,0,0,3V15a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V3A3,3,0,0,0,15,0Zm2,15a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V3A2,2,0,0,1,3,1H15a2,2,0,0,1,2,2Z" fill="#17b97c"/><path d="M14.28,4.66a1,1,0,0,0-1.39,0L7.33,10,5.11,7.86a1,1,0,0,0-1.39,0l-.64.62a1,1,0,0,0,0,1.44l3.56,3.42a1,1,0,0,0,1.39,0l6.89-6.62a1,1,0,0,0,0-1.44Z" fill="#17b97c"/></svg>`
);

const checkboxLabel = css`
  display: block;
  margin: 0;
  cursor: pointer;
  text-align: left;

  .pseudo-input::before,
  .pseudo-input::after {
    transition: background ${variables.transitionSpeed} ease;
  }
  .pseudo-input::before {
    background: url("data:image/svg+xml;charset=utf8,${checkboxUnselectedIcon}")
      0 center no-repeat;
  }
  .pseudo-input::after {
    background: url("data:image/svg+xml;charset=utf8,${checkboxSelectedIcon}") 0
      center no-repeat;
  }
`;

const inputWrapper = css`
  display: grid;
  grid-template-columns: ${pxToRem(20)} auto;
  grid-gap: 0.5rem;
  align-items: center;
`;

const checkbox = css`
  appearance: none;
  position: absolute;
  outline: none;
  opacity: 0;

  &:checked + .pseudo-input::after {
    opacity: 1;
  }
  + .pseudo-input {
    position: relative;
    cursor: pointer;
  }
  + .pseudo-input::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    transform: translate(0, -50%);
    opacity: 1;
  }
  + .pseudo-input::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    line-height: ${pxToRem(20)};
    transform: translate(0, -50%);
    text-align: center;
    opacity: 0;
  }
  &:disabled + .pseudo-input {
    opacity: ${variables.disabledOpacity};
    cursor: not-allowed;
  }

  &:hover + .pseudo-input::before,
  &:focus + .pseudo-input::before {
    background: url("data:image/svg+xml;charset=utf8,${checkboxUnselectedHoverIcon}")
      0 center no-repeat;
  }
  &:hover + .pseudo-input::after,
  &:focus + .pseudo-input::after {
    background: url("data:image/svg+xml;charset=utf8,${checkboxSelectedHoverIcon}")
      0 center no-repeat;
  }
`;

const labelTextAndCount = css`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${pxToRem(2)};
`;

const labelText = css`
  ${h5Regular};
`;

const labelCount = css`
  ${h5Regular};
`;

type Input = {
  id?: string;
  name?: string;
  children?: ReactElement | string;
  style?: SerializedStyles;
  checkedStyle?: SerializedStyles;
  disabled?: boolean;
  isChecked: boolean;
  label?: string | ReactElement;
  countLabel?: number;
  shouldTruncate?: boolean;
  onToggle: (boolean) => void;
  dataTest?: string;
  dataIntercomTarget?: string;
};

const CheckboxWithLabel = React.forwardRef<HTMLInputElement, Input>(
  (
    {
      id,
      name,
      children,
      checkedStyle,
      style,
      isChecked = false,
      disabled = false,
      label,
      countLabel,
      shouldTruncate = false,
      onToggle,
      dataTest,
      dataIntercomTarget,
    }: Input,
    ref
  ): ReactElement => {
    const [localIsChecked, setLocalIsChecked] = useState(isChecked);

    useEffect(() => {
      setLocalIsChecked(isChecked);
    }, [isChecked]);

    const onChange = (e) => {
      setLocalIsChecked(e.target.checked);

      if (onToggle) {
        onToggle(e.target.checked);
      }
    };

    const onLabelClick = (e) => {
      e.stopPropagation();
    };

    return (
      <label
        css={[checkboxLabel, style, localIsChecked && checkedStyle]}
        data-test={dataTest}
        onKeyDown={() => null}
        onClick={onLabelClick}
        data-intercom-target={dataIntercomTarget}
      >
        <div css={inputWrapper}>
          <input
            id={id}
            name={name}
            css={checkbox}
            type="checkbox"
            onChange={onChange}
            checked={localIsChecked}
            disabled={disabled}
            ref={ref}
          />
          <span className="pseudo-input" />
          <span css={labelTextAndCount}>
            <span css={[labelText, shouldTruncate && truncate]}>{label}</span>
            {countLabel > 0 && <span css={labelCount}>({countLabel})</span>}
          </span>
        </div>
        {children}
      </label>
    );
  }
);

CheckboxWithLabel.displayName = "CheckboxWithLabel";

export default CheckboxWithLabel;

import axios from "axios";
import qs from "qs";

import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const { apiHost } = publicRuntimeConfig;

const api = axios.create({
  baseURL: apiHost,
  paramsSerializer: (params) =>
    qs.stringify(params, {
      arrayFormat: "brackets",
      skipNulls: true,
    }),
});

const setAuthorization = (token) => {
  api.defaults.headers["Authorization"] = `Bearer ${token}`;
};

const removeAuthorization = () => {
  api.defaults.headers["Authorization"] = null;
};

const { CancelToken } = axios;

export default api;
export { CancelToken, setAuthorization, removeAuthorization };

import { css } from "@emotion/react";
import pxToRem from "./px-to-rem";
import { h5Bold } from "./typography";
import variables from "./variables";

const selectOptionStyles = {
  optionItem: css`
    ${h5Bold};
    display: grid;
    justify-content: start;
    align-items: center;
    padding: ${variables.spaceMicro} ${variables.spaceMicro}
      ${variables.spaceMicro} ${pxToRem(40)};
    grid-auto-flow: column;
    column-gap: ${pxToRem(7)};
    cursor: pointer;

    :focus,
    :hover {
      background-color: ${variables.colorBackground};
    }
  `,
  optionItemDisabled: css`
    color: ${variables.colorPrimaryGray};
    background-color: ${variables.colorContainerGray};
    cursor: not-allowed;

    :focus,
    :hover {
      background-color: ${variables.colorContainerGray};
    }
  `,
  selectedOptionItem: css`
    background-color: ${variables.colorBackground};
    padding: ${variables.spaceMicro} ${variables.spaceMicro}
      ${variables.spaceMicro} ${pxToRem(9)};
  `,
};

export default selectOptionStyles;

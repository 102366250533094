import { css } from "@emotion/react";
import React from "react";
import i18n from "../../../helpers/i18n";
import pxToRem from "../../../styles/px-to-rem";
import selectOptionStyles from "../../../styles/select-option";
import variables from "../../../styles/variables";
import DoubleCheckIcon from "../../../public/static/icons/double-check.svg";

const styles = {
  checkIcon: css`
    height: ${pxToRem(22)};
    width: ${pxToRem(24)};
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      fill: ${variables.colorPrimaryGray};
    }
  `,
};

const MultiSelectOptionAll = ({
  isChecked,
  onClick = () => {},
}: MultiSelectOptionAllProps) => {
  const label = i18n.t("selectAll");

  return (
    <div
      css={[
        selectOptionStyles.optionItem,
        isChecked && selectOptionStyles.selectedOptionItem,
      ]}
      aria-hidden="true"
      title={label}
      onKeyUp={onClick}
      onClick={onClick}
    >
      {isChecked && (
        <div css={styles.checkIcon}>
          <DoubleCheckIcon />
        </div>
      )}
      <div>{label}</div>
    </div>
  );
};

export default MultiSelectOptionAll;

import React, {
  useState,
  useRef,
  useEffect,
  ReactElement,
  useCallback,
} from "react";
import { createPortal } from "react-dom";
import { css } from "@emotion/react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import debounce from "lodash/debounce";

import usePortal from "../../hooks/use-portal";
import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import getFlyoutPosition from "../../helpers/get-flyout-position";
import useWindowSize from "../../hooks/use-window-size";

const flyoutMenu = css`
  position: fixed;
  z-index: -1;
  opacity: 0;
  transform: scale(0.9);
  transform-origin: center 0;
  min-width: ${pxToRem(180)};
  min-height: ${pxToRem(240)};
  max-height: ${pxToRem(600)};
  transition-duration: ${variables.transitionSpeedFast};
  transition-timing-function: ease-in-out;
  transition-property: transform;
  pointer-events: none;

  :focus {
    outline: none;
  }
`;

const flyoutMenuInner = css`
  background-color: ${variables.colorWhite};
  border-radius: ${variables.roundness2};
  border: ${pxToRem(1)} solid ${variables.colorSeparator};
  box-shadow: 0 ${pxToRem(5)} ${pxToRem(10)} rgba(0, 0, 0, 0.15);
  overflow: auto;

  :focus {
    outline: none;
  }
`;

const flyoutMenuOpen = css`
  z-index: 13;
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
`;

const Flyout = ({
  id,
  children,
  className,
  trigger,
  triggerType = "click",
  offsets,
  preferredAlignment,
  isOpen = false,
  dropdownMatchTriggerWidth = false,
  onOpen,
  onClose,
}: FlyoutProps): ReactElement => {
  const target = usePortal("dropdown-root");
  const rootRef = useRef<HTMLDivElement>();
  const triggerRef = useRef<HTMLDivElement>();
  const menuRef = useRef<HTMLDivElement>();
  const positionIntervalRef = useRef<NodeJS.Timer>(undefined);

  const [isLocalOpen, setIsLocalOpen] = useState(isOpen);
  const [menuTabIndex, setMenuTabIndex] = useState(-1);
  const [position, setPosition] = useState(null);
  const [width, setWidth] = useState<number>(undefined);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (isOpen !== isLocalOpen) {
      setIsLocalOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (!triggerRef.current || !dropdownMatchTriggerWidth) {
        return;
      }

      const { clientWidth } = triggerRef.current;
      setWidth(clientWidth);
    }, 10);

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dropdownMatchTriggerWidth, trigger]);

  useEffect(() => {
    if (isLocalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
      positionIntervalRef.current = setInterval(updatePosition, 100);
      setMenuTabIndex(0);
      updatePosition();

      // Focus on the invisible first child to prepare for keyboard interaction
      const firstChildNode = menuRef?.current.childNodes[0] as HTMLElement;
      if (firstChildNode) {
        firstChildNode.focus();
        firstChildNode.tabIndex = 0;
      }

      if (onOpen) {
        onOpen();
      }
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
      clearInterval(positionIntervalRef.current);
      if (onClose) {
        onClose();
      }
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
      clearInterval(positionIntervalRef.current);
    };
  }, [isLocalOpen]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (isLocalOpen && currPos.y !== prevPos.y) {
        updatePosition();
      }
    },
    [isLocalOpen]
  );

  useEffect(() => {
    if (isLocalOpen) {
      updatePosition();
    }
  }, [windowSize]);

  const handleClickOutside = (e) => {
    if (
      rootRef?.current?.contains(e?.target) ||
      menuRef?.current?.contains(e?.target)
    ) {
      return;
    }

    setIsLocalOpen(false);
  };

  const updatePosition = useCallback(() => {
    const newPosition = getFlyoutPosition(
      rootRef?.current,
      menuRef?.current,
      offsets,
      preferredAlignment
    );

    setPosition(newPosition);
  }, [offsets, preferredAlignment]);

  const toggleLocalOpen = (e) => {
    e?.stopPropagation();
    setIsLocalOpen(!isLocalOpen);
  };

  const handleOnMouseEnter = () => {
    if (triggerType === "hover" && isLocalOpen === false) {
      setTimeout(() => {
        setIsLocalOpen(true);
      });
    }
  };

  const handleOnMouseLeave = () => {
    if (triggerType === "hover" && isLocalOpen === true) {
      setTimeout(() => {
        setIsLocalOpen(false);
      });
    }
  };

  return (
    <div
      ref={rootRef}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <div className="trigger-container" ref={triggerRef}>
        {React.cloneElement(trigger, {
          id,
          "aria-haspopup": true,
          "aria-expanded": isLocalOpen,
          role: "menubutton",
          onClick: toggleLocalOpen,
          onMouseDown: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
        })}
      </div>
      {isLocalOpen &&
        createPortal(
          <div
            ref={menuRef}
            css={[
              flyoutMenu,
              isLocalOpen && flyoutMenuOpen,
              triggerType === "hover" &&
                css`
                  padding-top: ${pxToRem(offsets.top)};
                  margin-top: -${pxToRem(offsets.top)};
                  padding-bottom: ${pxToRem(offsets.bottom)};
                  margin-bottom: -${pxToRem(offsets.bottom)};
                `,
            ]}
            className={className}
            role="menu"
            aria-labelledby={id}
            style={{
              ...position,
              width: dropdownMatchTriggerWidth ? width : undefined,
            }}
            tabIndex={menuTabIndex}
          >
            <div css={flyoutMenuInner}>{children}</div>
          </div>,
          target
        )}
    </div>
  );
};

export default Flyout;
